<template>
  <form v-if="value" class="extstatform statistics__extstatform">
    <div
      class="d-flex flex-column flex-lllg-row justify-content-between justify-content-lllg-start"
    >
      <div class="set-prt-b mr-4 mr-lg-5">
        <div class="mb-2">
          <h4 class="settings__title--small mb-3">
            {{ !prTypeNumber ? 'Формат' : `Формат рекламы №${prTypeNumber}` }}
          </h4>
          <div class="d-flex justify-content-between flex-row flex-wrap">
            <b-button-group class="w-100">
              <b-button
                :class="{ error: $v.value.kind.$anyError }"
                v-for="option in kindsOptions"
                :key="option.value"
                :variant="
                  (postFormatValues.includes(value.kind) &&
                    postFormatValues.includes(option.value)) ||
                  option.value === value.kind
                    ? 'primary'
                    : 'outline-default'
                "
                class="ph-42"
                @click="update('kind', option.value)"
                >{{ option.display_name }}</b-button
              >
            </b-button-group>
          </div>
          <b-collapse :visible="postFormatValues.includes(value.kind)">
            <h4 class="settings__title--small">Тип поста</h4>
            <div class="d-flex justify-content-between flex-row flex-wrap">
              <b-form-radio-group
                style="font-size: 14px !important"
                buttons
                text-field="display_name"
                value-field="value"
                size="lg"
                button-variant="outline-default"
                :options="postKindsOptions"
                v-model="value.kind"
                class="w-100"
              >
              </b-form-radio-group>
            </div>
          </b-collapse>
        </div>
        <b-form-group
          label-class="ws-nowrap"
          class="mb-4"
          v-if="['1', '4'].includes(this.value.kind)"
        >
          <label for="sec-in" class="settings__title--small" style="margin-top: 0"
            >Продолжительность рекламы в сторис</label
          >
          <div class="d-flex flex-wrap justify-content-between">
            <template v-for="option in options.pr_types.child.children.duration.choices">
              <LeftPointedRadioButton
                :key="`${option.value}-optnPrTpFrm43s`"
                :variant="option.value === value.duration ? 'primary' : 'outline-default'"
                @click="update('duration', option.value)"
                :class="{ error: $v.value.duration.$anyError }"
                class="mr-1 mt-2 fmt-rd ph-36"
              >
                {{ option.display_name }} секунд
              </LeftPointedRadioButton>
            </template>
          </div>
        </b-form-group>
        <div
          style="border-bottom: none"
          class="d-flex flex-column flex-sssm-row justify-content-md-between prc-frm"
        >
          <b-form-group label-class="ws-nowrap" class="fmt-blc mr-3">
            <label
              for="advert-price"
              class="settings__title--small settings__title--small--margin ws-nowrap"
            >
              Цена за рекламу в ₽
            </label>
            <input
              type="number"
              @input="update('uPrice', Number($event.target.value))"
              :value="value.uPrice"
              :class="{ error: $v.value.uPrice.$anyError }"
              class="input settings__input stata__input"
              name="advert-price"
              id="advert-price"
            />
          </b-form-group>
          <b-form-group class="fmt-blc">
            <label for="month-in" class="settings__title--small settings__title--small--margin">
              Набор рекламы на
            </label>
            <Multiselect
              :class="{ error: $v.value.month.$anyError }"
              placeholder="Месяц"
              :show-labels="false"
              :value="currentMonth"
              @input="update('month', $event.value)"
              :options="options.pr_types.child.children.month.choices"
              track-by="value"
              id="month-in"
              class="w-100"
              label="display_name"
            />
          </b-form-group>
        </div>
      </div>
      <div class="set-prt-b ml-lllg-5">
        <div class="mb-5">
          <h4 class="settings__title--small">Кто может заказать</h4>
          <div class="d-flex justify-content-between flex-row flex-wrap">
            <template v-for="option in options.pr_types.child.children.target.choices">
              <LeftPointedRadioButton
                :key="`${option.value}-o124kirTpFrm43s`"
                :variant="option.value === value.target ? 'primary' : 'outline-default'"
                @click="update('target', option.value)"
                :class="{ error: $v.value.target.$anyError }"
                class="mr-1 mt-2 fmt-rd ph-36"
              >
                {{ option.display_name }}
              </LeftPointedRadioButton>
            </template>
          </div>
        </div>
        <div v-if="['1', '4'].includes(value.kind)">
          <h3 class="settings__title--small d-inline-block mr-3 mb-1">Что входит в рекламу</h3>
          <b-button class="d-inline-block py-1" variant="outline-default">?</b-button>
          <p class="gray-text fs-12 mb-3">(необязательно)</p>
          <div
            class="d-flex mb-3 align-items-center justify-content-between"
            style="min-witdh: 220px"
            v-for="tp in contentValues"
            :key="tp.model"
          >
            <p class="mr-3">{{ tp.title }}</p>
            <b-form-radio-group
              buttons
              size="lg"
              text-field="display_name"
              value-field="value"
              button-variant="outline-default"
              :options="contentValuesMask"
              v-model="value[tp.model]"
            ></b-form-radio-group>
          </div>
        </div>
        <div class="mt-auto">
          <b-button
            @click="$emit('copy', value)"
            style="align-self: flex-end !important"
            class="fs-16 w-100 mb-3 mt-3"
            variant="outline-default"
            >Дублировать формат</b-button
          >
          <div class="settings__buttons statistics__settings--buttons">
            <a
              @click="() => $emit('delete', this.value.id)"
              class="settings__title--blue statistics__format--remove"
              >Удалить этот формат</a
            >
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Multiselect from 'vue-multiselect';
import LeftPointedRadioButton from '@main/components/reusable/elements/buttons/LeftPointedRadioButton.vue';
import { mapState } from 'vuex';
import { required, minLength, between, minValue } from 'vuelidate/lib/validators';
import ReviewAdTypeSelect from '../../forms/ReviewAdTypeSelect.vue';

export default {
  props: {
    value: {
      type: Object,
    },
    prTypeNumber: { type: Number, default: null },
  },
  watch: {
    'value.kind': {
      handler(val) {
        console.log(val);
      },
    },
  },
  data: () => ({
    postFormatValues: ['2', '3'],
    contentValues: [
      { model: 'warmups', title: 'Подводок шт.' },
      { model: 'reccomends', title: 'Рекомендаций шт.' },
      { model: 'layouts', title: 'Макетов шт.' },
    ],
  }),
  validations: {
    value: {
      kind: { required },
      duration: {
        async required(val) {
          return !['1', '4'].includes(this.value.kind) || val;
        },
      },
      uPrice: {
        required,
        minValue: minValue(10),
        async wholeNum(val) {
          return val % 1 === 0;
        },
      },
      target: { required },
      month: { required },
      validationGroup: [
        'value.kind',
        'value.duration',
        'value.uPrice',
        'value.month',
        'value.target',
      ],
    },
  },
  components: {
    Multiselect,
    LeftPointedRadioButton,
  },
  computed: {
    ...mapState(['bloggerOptions']),
    options() {
      return this.bloggerOptions.actions.POST;
    },
    currentMonth() {
      return this.options.pr_types.child.children.month.choices.filter(
        (val) => val.value === this.value.month
      )[0];
    },
    kindsOptions() {
      const kinds = this.options.pr_types.child.children.kind.choices;
      const nsp = kinds.filter((opt) => !this.postFormatValues.includes(opt.value));
      nsp.splice(1, 0, { display_name: 'Пост', value: '2' });
      return nsp;
    },
    postKindsOptions() {
      return this.options.pr_types.child.children.kind.choices.filter((val) =>
        this.postFormatValues.includes(val.value)
      );
    },
    contentValuesMask() {
      return ['-', 1, 2, 3].map((val) => {
        let value;
        if (typeof val === 'string') {
          value = null;
        } else {
          value = val;
        }
        return { display_name: val, value };
      });
    },
  },
  methods: {
    update(key, value) {
      this.$v.value[key].$touch();
      this.$emit('input', { ...this.value, [key]: value });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .btn-lg,
.btn-group-lg > .btn {
  padding: 9.5px 16px !important;
  font-size: 14px !important;
}

.seconds__input-small {
  width: 159.8px;
  border-radius: 5px;
}
.statistics__settings--buttons {
  justify-content: flex-start;
}

.set-prt-b {
  width: 48%;
  max-width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1139px) {
  .set-prt-b {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (max-width: 400px) {
  .prc-frm {
    max-width: 190px !important;
  }
}
.fmt-rd {
  width: 48% !important;
  min-width: 142px !important;
}
@media (max-width: 380px) {
  .fmt-rd {
    min-width: 136px !important;
    font-size: 13px !important;
  }
}
@media (max-width: 331px) {
  .fmt-rd {
    min-width: 120px !important;
    font-size: 12px !important;
  }
}
.fmt-blc {
  width: 170px;
}
</style>
