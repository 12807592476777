import { render, staticRenderFns } from "./PrTypeForm.vue?vue&type=template&id=3bfd8384&scoped=true&"
import script from "./PrTypeForm.vue?vue&type=script&lang=js&"
export * from "./PrTypeForm.vue?vue&type=script&lang=js&"
import style0 from "./PrTypeForm.vue?vue&type=style&index=0&id=3bfd8384&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bfd8384",
  null
  
)

export default component.exports