<script>
import validateFunc from '@main/func/validations';
import {
  required,
  minLength,
  between,
  minValue,
  maxValue,
  requiredIf,
  email,
} from 'vuelidate/lib/validators';

export default {
  validations: {
    model: {
      blogger_profile: {
        coverage_screens: {
          required,
          minLength: minLength(1),
        },
        posts_coverage_screens: {
          // eslint-disable-next-line func-names
          requiredIf: requiredIf(function () {
            return (
              this.model.blogger_profile.coverage_posts_min ||
              this.model.blogger_profile.coverage_posts_max
            );
          }),
          minLength: minLength(1),
        },
        women_percent_screens: {
          required,
          minLength: minLength(1),
        },
        coverage_min: {
          // eslint-disable-next-line func-names
          requiredIf: requiredIf(function () {
            return !this.noStories;
          }),
          minVal(val) {
            return !this.noStories ? val >= 100 : true;
          },
          whole: (val) => val % 1 === 0,
        },
        coverage_max: {
          // eslint-disable-next-line func-names
          requiredIf: requiredIf(function () {
            return !this.noStories;
          }),
          minVal(val) {
            return !this.noStories ? val >= 100 : true;
          },
          whole: (val) => val % 1 === 0,
        },
        coverage_posts_min: {
          minVal(val) {
            return this.noStories ? val >= 100 : true;
          },
          whole: (val) => val % 1 === 0,
        },
        coverage_posts_max: {
          minVal(val) {
            return this.noStories ? val >= 100 : true;
          },
          whole: (val) => val % 1 === 0,
        },
        females_percent: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(100),
          whole: (val) => val % 1 === 0,
        },
        main_country: {
          coverage: { required, minValue: minValue(1), maxValue: maxValue(100) },
          country: { required, notUndef: async (val) => val && val.short_key !== 'NAN' },
          screens: {
            required,
            minLength: minLength(1),
          },
        },
        main_audience: {
          age_group: { required, notUndef: async (val) => !!val },
          age_group_percent: {
            required,
            minValue: minValue(1),
            maxValue: maxValue(100),
            whole: (val) => val % 1 === 0,
          },
          screens: {
            required,
            minLength: minLength(1),
          },
        },
        main_city: {
          city: { required, notUndef: async (val) => val && val.short_key !== 'NAN' },
          coverage: {
            required,
            minValue: minValue(1),
            maxValue: maxValue(100),
            whole: (val) => val % 1 === 0,
          },
          screens: {
            required,
            minLength: minLength(1),
          },
        },
      },
    },
  },
};
</script>
